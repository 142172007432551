<template>
  <div class="chipWithRole">
    <div class="chipCustom">
      <!-- <img
        v-if="accountData.picture"
        :src="getImage(accountData.picture)"
        class="avatar"
        width="24" height="24"
      /> -->
      <!-- <span
        v-if="accountData.picture"
        class="avatar"
        :style="{ backgroundImage: `url(${getImage(accountData.picture)})` }"
        width="24"
        height="24"
      >
      </span> -->

      <div v-if="accountData.picture" class="avatar">
        <img :src="getImage(accountData.picture)" class="avatar" width="24" height="24" />
      </div>

      <!-- Fallback for displaying the first letter when there is no picture -->
      <div v-else class="avatar" width="24" height="24">
        {{ accountData.name.substring(0, 1).toUpperCase() }}
      </div>
      {{ text }}
      <!-- Indicator for presentToday status -->

      <span v-if="presentToday !== undefined" class="present-indicator" :class="{
        'present-true': presentToday,
        'present-false': !presentToday,
      }"></span>

      <span v-if="
        extraText.toLowerCase() != 'manager' &&
        extraText.toLowerCase() != 'apimanager'
      " @click="$emit('crossClick')">
        <v-icon class="material" style="font-size: 18px;">mdi-close-circle</v-icon>
      </span>
    </div>
    <div class="chipExtra">
      {{ extraText }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["text", "extraText", "presentToday", "accountData"], // Added `presentToday` prop
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["ENDPOINT", "selectedTeam"]),
  },
  mounted() { },
  created() {
    console.log("accountData", this.accountData);
  },
  methods: {
    getImage(src) {
      console.log("src", src);
      return `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
    },
  },
};
</script>

<style scoped>
.chipWithRole {
  margin: 6px;
  display: inline-grid;
  align-items: center;
  /* Vertically center items */
  vertical-align: top;
  /* Align the container itself to the top */
}

.chipCustom {
  background-color: #d7d4d4;
  color: black;
  border-radius: 10px;
  padding: 6px;
  z-index: 2 !important;
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.chipExtra {
  color: white;
  padding: 1%;
  font-size: 4pt;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -8px;
  border-radius: 5px;
  background-color: #1bca3f;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  display: table-cell;
  vertical-align: bottom;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  /* Grey background */
  color: white;
  /* Text color */
  border-radius: 50%;
  /* Makes it round */
  width: 24px;
  /* Set width */
  height: 24px;
  /* Set height */
  font-size: 12px;
  overflow: hidden;
  /* Adjust font size if needed */
}

.avatar img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.present-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -5px;
}

.present-true {
  background-color: rgb(17, 193, 17);
}

.present-false {
  background-color: rgb(212, 16, 16);
}
</style>
